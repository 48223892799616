<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12" md="12">
            <b-card no-body>
              <b-card-header>
                <h4 class="card-title">{{i18nT(`Team`)}}</h4>
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item :to="{ name: 'edit-employee-group', params: { id: hasRouteId } }">
                    <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onCopy()">
                    <span class="align-middle ml-50">{{i18nT(`Copy`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="onSave()">
                    <span class="align-middle ml-50">{{i18nT(`Save`)}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="hasRouteId" @click="onDelete()">
                    <span class="align-middle ml-50" style="color: red">{{i18nT(`Delete`)}}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-card-header>
              <b-card-body>
                <b-row class="border-bottom">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <h5>
                      {{i18nT(`Group information`)}}
                    </h5>
                    <p class="mt-1">{{i18nT(`Create teams and employee groups to better organise your employees.`)}}</p>
                  </b-col>
                  <b-col
                    cols="12"
                    md="9"
                  >
                    <b-row>
                      <b-col>
                        <b-form-group
                          :label="i18nT(`Title`)"
                          label-for="title"
                          class="required"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="i18nT(`Title`)"
                            rules="required"
                          >
                            <b-form-input
                              id="title"
                              v-model="employeeGroup.Label"
                              :state="errors.length > 0 ? false:null"
                              disabled
                            />                              
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>                    
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          :label="i18nT(`Description`)"
                          label-for="description"
                        >
                          <b-form-textarea
                            id="textarea-default"
                            rows="3"
                            v-model="employeeGroup.Description"
                            disabled
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="border-bottom pt-2 pb-2">
                  <!-- Field: Username -->
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <h5>
                      {{i18nT(`Employees`)}}
                    </h5>
                  </b-col>
                  <b-col
                    cols="12"
                    md="9"
                  >
                    <div class="mt-1" v-if="selectedEmployee.length > 0">
                      <b-list-group>
                        <b-list-group-item v-for="employee in selectedEmployee" :key="employee.Id">
                          <div>
                                                        <b-avatar
                              v-b-tooltip.hover="employee.FirstName + ' ' + employee.LastName"
                              :key="employee.Id"
                              :src="employee.ImageUrl"
                              :text="(employee.FirstName? employee.FirstName.charAt(0) : '') + (employee.LastName ? employee.LastName.charAt(0) : '')"
                              class="badge-light-primary mr-1"
                            />
                            <span class="document-name">{{employee.title}}</span>
                            <b-button-group
                              size="sm"
                              class="document-actions-bar"
                            >
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                :to="{
                                  name: 'edit-employee',
                                  params: { id: employee.Id },
                                }"
                              >
                                <feather-icon
                                  icon="Edit3Icon"
                                  size="16"
                                  style="margin: 0;"
                                />
                              </b-button>
                            </b-button-group>
                          </div>
                        </b-list-group-item>
                      </b-list-group>                  
                    </div> 
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-modal
      id="modal-1"
      :title="i18nT(`Add new`)"
      ok-only
      :ok-title="i18nT(`Save`)"   
      no-close-on-backdrop
      @ok="onEmployeeSelect"
    >
      <div>
        <h5>
          {{ i18nT(`Select one or more employees.`) }}
        </h5>
        <b-form-group
          :label="i18nT(`Select Employee(s)`)"
          label-for="assignee"
        >
          <v-select
            v-model="selectedEmployeeTemp"
            multiple
            label="title"
            :options="employeeOptions"
          />
        </b-form-group>
      </div>
    </b-modal>
  </div>
  
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BFormTextarea,
  BCardBody,
  BModal,
  VBModal,
  BButtonGroup,
  BListGroup,
  BListGroupItem,
  BCardHeader,
  BDropdown, 
  BDropdownItem,
  BAvatar,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
//import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
//import { ref } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardBody,
    vSelect,
    BFormTextarea,
    BModal,
    BButtonGroup,
    BListGroup,
    BListGroupItem,    
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BDropdown, 
    BDropdownItem,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      employeeOptions: [],
      employees: [],
      selectedEmployeeTemp: [],
      selectedEmployee: [],
      employeeGroup: {
        Description: '',
        Label: ''
      },
      required,
      email
    }
  },
  created() {    
    this.$http.get(
      `employees?show_per_page=100`
    ).then(({data}) => {
      this.employees = data.data.records
      this.employeeOptions = data.data.records.map(employee => ({
        value: employee.user.Id,
        title: employee.user.Label,
      }))
    })

    if(router.currentRoute.params.id) {
      this.$http.get(
        `employees/employeeGroups?id=${router.currentRoute.params.id}`
      ).then(({data}) => {
        this.employeeGroup = data.data.group
        this.selectedEmployeeTemp = this.selectedEmployee = data.data.employees.map((employee) => ({
          value: employee.Id,
          title: employee.Label,
          ...employee
        }))

        console.log(this.selectedEmployee)
      })
    }
  },
  setup() {
    return  {
    }
  },
  computed: {
    hasRouteId() {
      return router && router.currentRoute.params.id
    }
  },
  methods: {
    onEmployeeSelect: function() {
      this.selectedEmployee = this.selectedEmployeeTemp
    },
    onEmployeeRemove: function(val) {
      this.selectedEmployee = this.selectedEmployee.filter((employee) => employee.value != val.value)
    },
    clearSelectedEmployee: function() {
      this.selectedEmployee = []
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          const formData = new FormData()
          formData.append('Label', this.employeeGroup.Label)
          formData.append('Description', this.employeeGroup.Description)
          formData.append('group_employees', this.selectedEmployee.map(employee => employee.value).join(","))
          if(router.currentRoute.params.id) {
            formData.append('id', router.currentRoute.params.id)
          }
          this.$http.post(
            'employees/employeeGroups', formData
          ).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Employee Group ${router.currentRoute.params.id ? 'updated': 'created'}.`,
                icon: 'InfoIcon',
                variant: 'success',
              },
            })

            router.push({name: 'employee-groups'})
          })
        }
      })
    },
    onSave() {
      console.log('Saved!!!')
    },
    onDelete() {
      this.$swal({
        title: 'Are you sure you want to delete this entry?',        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          console.log('Deleted!!!')
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .section-title {
    font-size: 11px;
  }
  .document-name {
    display: inline;
    line-height: 32px;
  }
  .document-actions-bar {
    float: right;
  }
</style>
